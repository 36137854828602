import React, { useEffect } from "react";
import { Sidemenu } from "../devlink/Sidemenu";
import { Topnav } from "../devlink/Topnav";
import "./users.css";
import AddNewPopup from "./popup/AddNewPpopup";
import WSS from "../services/websocket";
import UserWatcher from "../UserWatcher";
import { ReactiveQuery } from "../QueryProvider";

export default function Users() {
    const wsServer = process.env.REACT_APP_WSS || "ws://localhost:5000";
    const wss = new WSS(wsServer);
    const client = new UserWatcher(wss);

    const { data, isLoading, refetch, hasNextPage, fetchNextPage }
        = new ReactiveQuery(wss.subscribe.bind(wss), ["fetchUser"])
            .findInfinite(({ last }) => client.fetchAllUsers({ last }, false, {}, true));

    useEffect(() => {
        refetch();
    }, [refetch]);

    const [toggleAddUser, setToggleAddUser] = React.useState(false);
    const [toggleEditUser, setToggleEditUser] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState({});
    const [togglePassword, setTogglePassword] = React.useState(false);

    const handleDeleteUser = async (user) => {
        const res = await client.deleteUser(user.id);
        if (res) {
            refetch();
            setSelectedUser({});
        }

    };

    const handleSaveUser = async (user) => {
        const res = await client.createUser(user);
        if (res) {
            refetch();
            setSelectedUser({});
            setToggleAddUser(false);
        }
    }

    const handleEditUser = async (user) => {
        const res = await client.updateUser(user);
        if (res) {
            refetch();
            setSelectedUser({});
            setToggleEditUser(false);
        }
    }

    const handleBlock = async (user) => {
        const res = await client.blockUser(user.id);
        if (res) {
            refetch();
            setSelectedUser({});
        }
    }

    return (
        <div className="pagewrap">
            <div className="icon_main-menu"  >
                <a className="link-block" href="#">
                    <img
                        loading="lazy"
                        width="auto"
                        height="auto"
                        alt=""
                        src="images/66968578d5889c78730e31bc_star.svg"
                    />
                </a>
            </div >
            <Sidemenu />
            <div className="maincontent_container">
                <Topnav />
                <div className="maincontent_body bg-gray">
                    <div className="manageuser-content">
                        <div className="manageuser-center">
                            <div className="user-col1">
                                <div className="inbox-top">
                                    <div className="phonetop_left">
                                        <h3 className="phoneheader_text">
                                            Users
                                        </h3>
                                    </div>
                                    <div className="phonetop_right">
                                        <a href="#" className="btn w-inline-block"
                                            onClick={() => {
                                                setToggleAddUser(true);
                                                setSelectedUser({});
                                                setToggleEditUser(false);
                                            }}>
                                            <div className="copylink_icon">
                                                <img
                                                    src="https://cdn.prod.website-files.com/6752b32a8d4f444fd87222f0/6790fae0085142b8481fd3aa_crm-user-add.svg"
                                                    loading="lazy"
                                                    alt=""
                                                />
                                            </div>
                                            <div>Add New</div>
                                        </a>
                                    </div>
                                </div>
                                <div className="user-search">
                                    <form action="/search" className="search w-form">
                                        <input
                                            className="user--search-input w-input"
                                            maxLength={256}
                                            name="query"
                                            placeholder="Search…"
                                            type="search"
                                            id="search"
                                            required=""
                                        />
                                        <input
                                            type="submit"
                                            className="search-button hide w-button"
                                            defaultValue=""
                                        />
                                    </form>
                                </div>
                                <div className="inbox-contain">
                                    {data.map((user, index) => (
                                        <div className="user-item"
                                            key={index}
                                            onClick={() => setSelectedUser(user)}>
                                            <div className="useritem_left">
                                                <div className="user-avatar">
                                                    <div>{user.firstName !== "" ? user.firstName.charAt(0).toUpperCase() : user.username.charAt(0).toUpperCase()}</div>
                                                </div>
                                                <div className="inboxtextcontent">
                                                    <div className="username">{user.firstName !== "" ? user.firstName : user.username}</div>
                                                </div>
                                            </div>
                                            <div className="useritem_right">
                                                <div className="user-optiondiv">
                                                    <div className="template-options">
                                                        <img
                                                            loading="lazy"
                                                            src={user.blocked ?
                                                                "https://cdn.prod.website-files.com/6752b32a8d4f444fd87222f0/6790f628ef5223f4e056458a_crm-user-stop.svg" :
                                                                "https://cdn.prod.website-files.com/677bd940823bc1c1d0d8502d/67912f6105da485fae76da7f_crm-user-check.svg"
                                                            }
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}
                                </div>
                            </div>
                            <div className="user-col2" >
                                <div className="userdetail-div"
                                    style={{ display: Object.keys(selectedUser).length > 0 ? "flex" : "none" }}
                                >
                                    <div className="userdetail-top">
                                        <div className="userdetail-topleft">
                                            <div className="userdetail-avatar">
                                                <div>{Object.keys(selectedUser).length > 0 && (selectedUser.firstName !== "" ? selectedUser.firstName.charAt(0).toUpperCase() : selectedUser.username.charAt(0).toUpperCase())}</div>
                                            </div>
                                            {/* <a href="#" className="btn-outlinegrey w-button">
                                                Upload
                                            </a> */}
                                        </div>
                                        <div className="userdetail-topright">
                                            <div className="phonetop_iconbtn" onClick={() => {
                                                setToggleEditUser(true)
                                                setToggleAddUser(false)
                                            }}>
                                                <img
                                                    loading="lazy"
                                                    src="https://cdn.prod.website-files.com/6752b32a8d4f444fd87222f0/6790fa107644d889d8fda965_crm-user-edit.svg"
                                                    alt=""
                                                    className="image-100"
                                                />
                                            </div>
                                            <div className="phonetop_iconbtn" onClick={() => handleDeleteUser(selectedUser)}>
                                                <img
                                                    loading="lazy"
                                                    src="https://cdn.prod.website-files.com/6752b32a8d4f444fd87222f0/6790fa1078f07e3408ed8e1a_crm-user-trash.svg"
                                                    alt=""
                                                    className="image-100"
                                                />
                                            </div>
                                            <div onClick={() => handleBlock(selectedUser)}
                                                style={{
                                                    cursor: "pointer",
                                                    boxShadow: "-7px -3px 9px #ffffffb3, 1px 2px 8px -1px #0003",
                                                    marginLeft: "5px",
                                                    paddingLeft: "4px",
                                                    color: "#fff",
                                                    paddingRight: "4px",
                                                    height: "30px",
                                                    borderRadius: "5px",
                                                    justifyContent: "center",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    backgroundColor: selectedUser.blocked ? "#6d42f8" : "#ff2222",
                                                }}
                                                onMouseEnter={(e) => e.target.style.backgroundColor = selectedUser.blocked ? "#6d42f8" : "#ff2222"}
                                                onMouseLeave={(e) => e.target.style.backgroundColor = selectedUser.blocked ? "#977ff5" : "#ff4444"}
                                            >
                                                <img
                                                    loading="lazy"
                                                    src={
                                                        Object.keys(selectedUser).length > 0 ? (selectedUser.blocked ?
                                                            "https://cdn.prod.website-files.com/677bd940823bc1c1d0d8502d/67912f6105da485fae76da7f_crm-user-check.svg" :
                                                            "https://cdn.prod.website-files.com/6752b32a8d4f444fd87222f0/6790f628ef5223f4e056458a_crm-user-stop.svg") : ""
                                                    }
                                                    alt=""
                                                    style={{ width: "15px", height: "15px" }}
                                                />
                                                &nbsp;
                                                {selectedUser.blocked ? "Unblock User" : "Block User"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="userdetails-row">
                                        <div className="userdetail-row-hd-div">
                                            <h4 className="userdetail-row-hd">Personal Details</h4>
                                        </div>
                                        <div className="userdetails-row-content">
                                            <div className="userdetails-row-item">
                                                <p className="p-style1 mb-5">Full Name</p>
                                                <p className="p-style1 text-bold">
                                                    <strong>{Object.keys(selectedUser).length > 0 ? `${selectedUser.firstName} ${selectedUser.lastName}` : ""}</strong>
                                                </p>
                                            </div>
                                            <div className="userdetails-row-item">
                                                <p className="p-style1 mb-5">Display Name</p>
                                                <p className="p-style1 text-bold">{Object.keys(selectedUser).length > 0 ? selectedUser.displayName : ""}</p>
                                            </div>
                                            <div className="userdetails-row-item">
                                                <p className="p-style1 mb-5">Mobile</p>
                                                <p className="p-style1 text-bold">{Object.keys(selectedUser).length > 0 ? selectedUser.mobileNumber : ""}</p>
                                            </div>
                                            <div className="userdetails-row-item">
                                                <p className="p-style1 mb-5">Email</p>
                                                <p className="p-style1 text-bold">{Object.keys(selectedUser).length > 0 ? selectedUser.email : ""}</p>
                                            </div>
                                            <div className="userdetails-row-item">
                                                <p className="p-style1 mb-5">Country</p>
                                                <p className="p-style1 text-bold">{Object.keys(selectedUser).length > 0 ? selectedUser.country : ""}</p>
                                            </div>
                                            <div className="userdetails-row-item">
                                                <p className="p-style1 mb-5">Address</p>
                                                <p className="p-style1 text-bold">{Object.keys(selectedUser).length > 0 ? selectedUser.address : ""}</p>
                                            </div>
                                            <div className="userdetails-row-item">
                                                <p className="p-style1 mb-5">City</p>
                                                <p className="p-style1 text-bold">{Object.keys(selectedUser).length > 0 ? selectedUser.city : ""}</p>
                                            </div>
                                            <div className="userdetails-row-item">
                                                <p className="p-style1 mb-5">State</p>
                                                <p className="p-style1 text-bold">{Object.keys(selectedUser).length > 0 ? selectedUser.state : ""}</p>
                                            </div>
                                            <div className="userdetails-row-item">
                                                <p className="p-style1 mb-5">ZIP Code</p>
                                                <p className="p-style1 text-bold">{Object.keys(selectedUser).length > 0 ? selectedUser.zip : ""}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="userdetails-row">
                                        <div className="userdetail-row-hd-div">
                                            <h4 className="userdetail-row-hd">Account Details</h4>
                                        </div>
                                        <div className="userdetails-row-content">
                                            <div className="userdetails-row-item">
                                                <p className="p-style1 mb-5">Username</p>
                                                <p className="p-style1 text-bold">{Object.keys(selectedUser).length > 0 ? selectedUser.username : ""}</p>
                                            </div>
                                            <div className="userdetails-row-item">
                                                <p className="p-style1 mb-5">Password</p>
                                                <div className="userdetail-passworddiv">
                                                    <div className="userdetail-password-view"
                                                        onClick={() => setTogglePassword(!togglePassword)}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <img
                                                            src="https://cdn.prod.website-files.com/6752b32a8d4f444fd87222f0/6791008b6080f58cea19e875_crm-user-eye.svg"
                                                            loading="lazy"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <p className="p-style1 text-bold">
                                                        <strong>
                                                            {togglePassword ? Object.keys(selectedUser).length > 0 && selectedUser.password : "********"}
                                                        </strong>
                                                    </p>

                                                </div>
                                            </div>
                                            <div className="userdetails-row-item">
                                                <p className="p-style1 mb-5">Role</p>
                                                <p className="p-style1 text-bold">Standard</p>
                                            </div>
                                            <div className="userdetails-row-item">
                                                <p className="p-style1 mb-5">Screen Capture</p>
                                                <p className="p-style1 text-bold">Yes</p>
                                            </div>
                                            <div className="userdetails-row-item">
                                                <p className="p-style1 mb-5">
                                                    Maintain Archive for Screen Capture
                                                </p>
                                                <p className="p-style1 text-bold">Yes</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="userdetails-row">
                                        <div className="userdetail-row-hd-div">
                                            <h4 className="userdetail-row-hd">Skills</h4>
                                        </div>
                                        <div className="userdetails-row-content"  >
                                            <div className="lead_tag">Developer</div>
                                            <div className="lead_tag">Design</div>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="userdetail-empty"
                                    style={{ display: Object.keys(selectedUser).length > 0 ? "none" : "flex" }}
                                >
                                    <div className="userdetail-empty-icon">
                                        <img
                                            src="https://cdn.prod.website-files.com/6752b32a8d4f444fd87222f0/6791008c9de8c786eb046cc7_crm-user-multiple.svg"
                                            loading="lazy"
                                            alt=""
                                        />
                                    </div>
                                    <h4 className="heading">No user selected</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <AddNewPopup
                isOpen={toggleAddUser || toggleEditUser}
                onClose={() => {
                    setToggleAddUser(false);
                    setToggleEditUser(false);
                }}
                label={() => {
                    if (toggleAddUser) return "Add New User";
                    if (toggleEditUser) return "Edit User";
                }}
                user={selectedUser}
                onSave={(user) => {
                    if (toggleAddUser)
                        handleSaveUser(user);
                    if (toggleEditUser)
                        handleEditUser(user);
                }}
            />
        </div >
    );
}