"use client";
import React, { useEffect, useState } from "react";
import * as _Builtin from "./_Builtin";
import { Conversation } from "./Conversation";
import { Transcription } from "./Transcription";
import * as _utils from "./utils";
import _styles from "./Inboxcol2.module.css";
import { DropdownMenu } from "../component/Dropdown";
import axios from "axios";

export function Inboxcol2({ as: _Component = _Builtin.Block, data = [], handleSendingMessage = () => { }, transcriptionData = [],
  handleTranscriptMessage = () => { }, handleClickInteraction = () => { }, baseInfo = {}, isFetchingInteractions = false,
  isFetchingTanscriptions = false, loadMore = () => { }, isLoadingMore = false, interactionId = "", recordingId = null
}) {

  const [currentTab, setCurrentTab] = useState("Tab 1");
  const [fadeClass, setFadeClass] = useState("active");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const buttonRef = React.useRef(null);

  useEffect(() => {
    setFadeClass("inactive");
    const timeout = setTimeout(() => setFadeClass("active"), 100);

    return () => clearTimeout(timeout);
  }, [currentTab]);

  useEffect(() => {
    setCurrentTab("Tab 1");
  }, [isFetchingInteractions]);

  const handleActiveTab = (tab) => {
    setCurrentTab(tab);
  };

  const downloadTranscript = async (interactionId) => {
    if (currentTab !== "Tab 2") return;
    const wsServer = process.env.REACT_APP_WSS || "http://localhost:5000"; // Ensure correct protocol (http/https)
    const url = `${wsServer}/api/downloadTranscript/${interactionId}`;

    try {
      const response = await axios.get(url, { responseType: "blob" });

      // Extract filename from Content-Disposition header if available
      const contentDisposition = response.headers['content-disposition'];
      let filename = `transcript_${interactionId}.docx`; // Default filename

      if (contentDisposition && contentDisposition.includes('attachment')) {
        const filenameMatch = contentDisposition.match(/filename="(.+)"/);
        if (filenameMatch && filenameMatch.length === 2) {
          filename = filenameMatch[1];
        }
      }

      // Create a Blob object from the response data
      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      // Create a download link and trigger the download
      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = urlBlob;
      link.download = filename;

      // Append link to the body, click it to trigger download, then remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
      console.error("Error in downloadTranscript", error.message || error);
    }
  };

  return (
    <_Component className={_utils.cx(_styles, "dashboard-col2")} tag="div">
      <_Builtin.Block
        className={_utils.cx(_styles, "conversation-main")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "conversationtop")}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "smstop_left")}
            tag="div"
          >
            <_Builtin.Block
              className={_utils.cx(_styles, "avatar", "larger")}
              tag="div"
            >
              <_Builtin.Image
                className={_utils.cx(_styles, "avatar_image")}
                width="auto"
                height="auto"
                loading="lazy"
                alt=""
                src={baseInfo.avatar}
              />
            </_Builtin.Block>
            <_Builtin.Block
              className={_utils.cx(_styles, "widget_headertextcontent")}
              tag="div"
            >
              <_Builtin.Heading
                className={_utils.cx(_styles, "phoneheader_text")}
                tag="h3"
              >
                {baseInfo.name}
              </_Builtin.Heading>
              <_Builtin.Block
                className={_utils.cx(_styles, "previewtext")}
                tag="div"
              >
                {baseInfo.address}
              </_Builtin.Block>
            </_Builtin.Block>
          </_Builtin.Block>
          <_Builtin.Block
            className={_utils.cx(_styles, "smstop_right")}
            tag="div"

          >
            <_Builtin.Block
              className={_utils.cx(_styles, "phonetop_iconbtn")}
              tag="div"
              onClick={() => { if (currentTab === "Tab 2") toggleDropdown(); }}

            >
              <_Builtin.Image
                className={_utils.cx(_styles, "image-100")}
                width="auto"
                height="auto"
                loading="lazy"
                alt=""
                src="images/66968578d5889c78730e31aa_icon_threedots.svg"
                ref={buttonRef}
              />
            </_Builtin.Block>
            <DropdownMenu
              isOpen={isDropdownOpen}
              onClose={() => setIsDropdownOpen(false)}
              anchorEl={buttonRef.current}
              options={["Export Transcript"]}
              onSelection={(option) => {
                switch (option) {
                  case "Export Transcript":
                    downloadTranscript(interactionId);
                    break;
                  default:
                    break;
                }
              }}
            />
          </_Builtin.Block>
        </_Builtin.Block>
        <div className={"convo-tabs"}>
          <div className={"convo-tabsmenu w-tab-menu"}  >
            <a className={`convo-tablink w-inline-block w-tab-link ${currentTab === "Tab 1" && "w--current"}`}
              onClick={() => setCurrentTab("Tab 1")}
            >
              <_Builtin.Block tag="div">{"Conversation"}</_Builtin.Block>
            </a>
            <a className={`convo-tablink w-inline-block w-tab-link ${currentTab === "Tab 2" && "w--current"}`}
              onClick={() => transcriptionData.length !== 0 && setCurrentTab("Tab 2")}
              title={transcriptionData.length === 0 ? "No Transcription Available" : ""}
            >
              <_Builtin.Block tag="div">{"Transcription"}</_Builtin.Block>
            </a>
          </div>
          <div className={`convo-tabpane ${currentTab === "Tab 1" ? fadeClass : ""}`}>
            {currentTab === "Tab 1" && (
              <Conversation data={data} handleSendingMessage={handleSendingMessage} handleClickInteraction={handleClickInteraction}
                handleActiveTab={handleActiveTab} isFetchingInteractions={isFetchingInteractions} loadMore={loadMore}
                isLoadingMore={isLoadingMore}
              />
            )}
          </div>
          <div className={`convo-tabpane ${currentTab === "Tab 2" ? fadeClass : ""}`}>
            {currentTab === "Tab 2" && (
              <Transcription data={transcriptionData} handleTranscriptMessage={handleTranscriptMessage}
                isFetchingTanscriptions={isFetchingTanscriptions} recordingId={recordingId}
              />
            )}
          </div>
        </div>
      </_Builtin.Block>
    </_Component>
  );
}
