import React, { useState, useEffect } from "react";
import { USER_ROLES } from "../../common/const";
const AddNewPopup = ({
    isOpen = false,
    onClose = () => { },
    label = () => { },
    user = {
        firstName: "Alex",
        lastName: "Jackson",
        displayName: "Alex",
        mobileNumber: "(555) 555-1234",
        email: "alexjackson@email.com",
        country: "United States",
        address: "1732 Willow Creek Lane",
        city: "Harmony",
        state: "Indiana",
        zip: "46743",
        username: "alex.j",
        password: "samplePassword",
        role: 0x1,
        avatar: "",
    },
    onSave = () => { }
}) => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        displayName: "",
        mobileNumber: "",
        email: "",
        country: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        username: "",
        password: "",
        role: "",
        avatar: "",
    });

    // Initialize form data when user prop changes
    useEffect(() => {
        if (user) {
            setFormData({
                displayName: user.displayName || "",
                firstName: user.firstName || "",
                lastName: user.lastName || "",
                mobileNumber: user.mobileNumber || "",
                email: user.email || "",
                country: user.country || "",
                address: user.address || "",
                city: user.city || "",
                state: user.state || "",
                zip: user.zip || "",
                username: user.username || "",
                password: "",
                role: user.role || "",
                avatar: user.avatar || ""
            });
        }
    }, [user]);

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const [initialData, setInitialData] = useState({});

    // Update initial data when user prop changes
    useEffect(() => {
        if (user) {
            const initial = {
                firstName: user.firstName || "",
                lastName: user.lastName || "",
                displayName: user.displayName || "",
                mobileNumber: user.mobileNumber || "",
                email: user.email || "",
                country: user.country || "",
                address: user.address || "",
                city: user.city || "",
                state: user.state || "",
                zip: user.zip || "",
                username: user.username || "",
                password: user.password || "",
                role: user.role || "",
                avatar: user.avatar || ""
            };
            setInitialData(initial);
        }
    }, [user]);

    // Handle form submission
    const handleSave = () => {
        // Create object with only changed fields
        const updatedFields = {};

        // Compare each field with initial value
        Object.keys(formData).forEach(key => {
            if (formData[key] !== initialData[key]) {
                updatedFields[key] = formData[key];
            }
        });
        updatedFields.id = user.id;
        // Only call onSave if there are changes
        if (Object.keys(updatedFields).length > 0) {
            onSave(updatedFields);
        }
    };


    const ROLE_DISPLAY_NAMES = {
        [USER_ROLES.STANDARD]: "Standard",
        [USER_ROLES.ADMIN]: "Admin",
        [USER_ROLES.SUPER_USER]: "Super Admin"
    };

    return (
        <div className="popup-wrap">
            <div
                tmq="extra-1-popup-adduser"
                className="createnewuser-popup"
                style={{ display: isOpen ? "flex" : "none" }}
            >
                <div className="createnewuser-popup-main">
                    <div className="createnewuser-popup-top">
                        <div className="popup_close" onClick={onClose}>
                            <img
                                loading="lazy"
                                src="https://cdn.prod.website-files.com/6752b32a8d4f444fd87222f0/6752b32a8d4f444fd8722311_icon_close.svg"
                                alt=""
                            />
                        </div>
                        <h3 tmq="cmp-col-headertext" className="phoneheader_text">
                            {label()}
                        </h3>
                    </div>
                    <div className="createnewuser-popup-body">
                        <div className="w-form">
                            <form name="wf-form-createnewuser-form" method="get">
                                {/* Display Picture Section */}
                                <div className="createnewuser-popup-formrow">
                                    <div className="formlabel-contain">
                                        <p className="p-style2">Display Picture</p>
                                    </div>
                                    <div className="forminput-contain">
                                        <div className="userdetail-topleft">
                                            <div className="userdetail-avatar">
                                                <div>{formData.displayName?.[0] || 'A'}</div>
                                            </div>
                                            <a href="#" className="btn-outlinegrey w-button">
                                                Upload
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {/* Personal Details Section */}
                                <div className="createnewuser-popup-group">
                                    <div className="userdetail-row-hd-div">
                                        <h4 className="userdetail-row-hd">Personal Details</h4>
                                    </div>
                                    <div className="createnewuser-popup-formrow">
                                        <div className="formlabel-contain">
                                            <p className="p-style2">Display Name</p>
                                        </div>
                                        <div className="forminput-contain">
                                            <input
                                                className="text-field mb-0 w-input"
                                                name="displayName"
                                                value={formData.displayName}
                                                onChange={handleInputChange}
                                                type="text"
                                            />
                                        </div>
                                    </div>

                                    <div className="createnewuser-popup-formrow">
                                        <div className="formlabel-contain">
                                            <p className="p-style2">First Name</p>
                                        </div>
                                        <div className="forminput-contain">
                                            <input
                                                className="text-field mb-0 w-input"
                                                name="firstName"
                                                value={formData.firstName}
                                                onChange={handleInputChange}
                                                type="text"
                                            />
                                        </div>
                                    </div>

                                    <div className="createnewuser-popup-formrow">
                                        <div className="formlabel-contain">
                                            <p className="p-style2">Last Name</p>
                                        </div>
                                        <div className="forminput-contain">
                                            <input
                                                className="text-field mb-0 w-input"
                                                name="lastName"
                                                value={formData.lastName}
                                                onChange={handleInputChange}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="createnewuser-popup-formrow">
                                        <div className="formlabel-contain">
                                            <p className="p-style2">Mobile</p>
                                        </div>
                                        <div className="forminput-contain">
                                            <input
                                                className="text-field mb-0 w-input"
                                                name="mobileNumber"
                                                value={formData.mobileNumber}
                                                onChange={handleInputChange}
                                                type="tel"
                                            />
                                        </div>
                                    </div>

                                    <div className="createnewuser-popup-formrow">
                                        <div className="formlabel-contain">
                                            <p className="p-style2">Email</p>
                                        </div>
                                        <div className="forminput-contain">
                                            <input
                                                className="text-field mb-0 w-input"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                type="email"
                                            />
                                        </div>
                                    </div>

                                    <div className="createnewuser-popup-formrow">
                                        <div className="formlabel-contain">
                                            <p className="p-style2">Country</p>
                                        </div>
                                        <div className="forminput-contain">
                                            {/* <select
                                                name="country"
                                                value={formData.country}
                                                onChange={handleInputChange}
                                                className="select-field w-select"
                                            >
                                                <option value="">Select country</option>
                                                <option value="United States">United States</option>
                                                <option value="Canada">Canada</option>
                                            </select> */}
                                            <input
                                                className="text-field mb-0 w-input"
                                                name="country"
                                                value={formData.country}
                                                onChange={handleInputChange}
                                                type="text"
                                            />
                                        </div>
                                    </div>

                                    <div className="createnewuser-popup-formrow">
                                        <div className="formlabel-contain">
                                            <p className="p-style2">Address</p>
                                        </div>
                                        <div className="forminput-contain">
                                            <input
                                                className="text-field mb-0 w-input"
                                                name="address"
                                                value={formData.address}
                                                onChange={handleInputChange}
                                                type="text"
                                            />
                                        </div>
                                    </div>

                                    <div className="createnewuser-popup-formrow">
                                        <div className="formlabel-contain">
                                            <p className="p-style2">City</p>
                                        </div>
                                        <div className="forminput-contain">
                                            <input
                                                className="text-field mb-0 w-input"
                                                name="city"
                                                value={formData.city}
                                                onChange={handleInputChange}
                                                type="text"
                                            />
                                        </div>
                                    </div>

                                    <div className="createnewuser-popup-formrow">
                                        <div className="formlabel-contain">
                                            <p className="p-style2">State</p>
                                        </div>
                                        <div className="forminput-contain">
                                            <input
                                                className="text-field  mb-0 w-input"
                                                name="state"
                                                value={formData.state}
                                                onChange={handleInputChange}
                                                type="text"
                                            />
                                        </div>
                                    </div>

                                    <div className="createnewuser-popup-formrow">
                                        <div className="formlabel-contain">
                                            <p className="p-style2">ZIP Code</p>
                                        </div>
                                        <div className="forminput-contain">
                                            <input
                                                className="text-field mb-0 w-input"
                                                name="zip"
                                                value={formData.zip}
                                                onChange={handleInputChange}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Account Details Section */}
                                <div className="createnewuser-popup-group">
                                    <div className="userdetail-row-hd-div">
                                        <h4 className="userdetail-row-hd">Account Details</h4>
                                    </div>

                                    <div className="createnewuser-popup-formrow">
                                        <div className="formlabel-contain">
                                            <p className="p-style2">Username</p>
                                        </div>
                                        <div className="forminput-contain">
                                            <input
                                                className="text-field mb-0 w-input"
                                                name="username"
                                                value={formData.username}
                                                onChange={handleInputChange}
                                                type="text"
                                            />
                                        </div>
                                    </div>

                                    <div className="createnewuser-popup-formrow">
                                        <div className="formlabel-contain">
                                            <p className="p-style2">Password</p>
                                        </div>
                                        <div className="forminput-contain">
                                            <input
                                                className="text-field mb-0 w-input"
                                                name="password"
                                                value={formData.password}
                                                onChange={handleInputChange}
                                                type="password"
                                            />
                                        </div>
                                    </div>

                                    <div className="createnewuser-popup-formrow">
                                        <div className="formlabel-contain">
                                            <p className="p-style2">Role</p>
                                        </div>
                                        <div className="forminput-contain">
                                            <select
                                                name="role"
                                                value={formData.role}
                                                onChange={handleInputChange}
                                                className="select-field w-select"
                                            >
                                                {Object.keys(USER_ROLES).map((role) => (
                                                    <option key={role} value={USER_ROLES[role]}>
                                                        {ROLE_DISPLAY_NAMES[USER_ROLES[role]]}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* <div className="createnewuser-popup-formrow">
                                        <div className="formlabel-contain" />
                                        <div className="forminput-contain">
                                            <label className="w-checkbox checkbox-field flex-horixontal">
                                                <input
                                                    type="checkbox"
                                                    name="screenCapture"
                                                    checked={formData.screenCapture}
                                                    onChange={handleInputChange}
                                                    className="w-checkbox-input checkbox"
                                                />
                                                <span className="p-style2 ml-5 w-form-label">
                                                    Screen Capture
                                                </span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="createnewuser-popup-formrow">
                                        <div className="formlabel-contain" />
                                        <div className="forminput-contain">
                                            <label className="w-checkbox checkbox-field flex-horixontal">
                                                <input
                                                    type="checkbox"
                                                    name="maintainArchive"
                                                    checked={formData.maintainArchive}
                                                    onChange={handleInputChange}
                                                    className="w-checkbox-input checkbox"
                                                />
                                                <span className="p-style2 ml-5 w-form-label">
                                                    Maintain Archive for Screen Capture
                                                </span>
                                            </label>
                                        </div>
                                    </div> */}
                                </div>
                                {/* <div className="createnewuser-popup-group">
                                    <div className="createnewuser-popup-formrow">
                                        <div className="formlabel-contain">
                                            <p className="p-style2">Skills</p>
                                        </div>
                                        <div className="forminput-contain">
                                            <div className="addskills-contain">
                                                <a href="#" className="btn-outline w-inline-block">
                                                    <div className="copylink_icon">
                                                        <img
                                                            loading="lazy"
                                                            src="https://cdn.prod.website-files.com/677bd940823bc1c1d0d8502d/67912ffbf3fdf85c2d8c0f30_crm-user-add-purple.svg"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div>Add Skills</div>
                                                </a>
                                                {formData.skills.map((skill, index) => (
                                                    <div key={index} className="skill-tag">
                                                        {skill}
                                                        <span
                                                            className="skill-remove"
                                                            onClick={() => {
                                                                setFormData(prev => ({
                                                                    ...prev,
                                                                    skills: prev.skills.filter((_, i) => i !== index)
                                                                }));
                                                            }}
                                                        >
                                                            {" "}×
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </form>
                            <div
                                className="w-form-done"
                                tabIndex={-1}
                                role="region"
                                aria-label="createnewuser-form success"
                            >
                                <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div
                                className="w-form-fail"
                                tabIndex={-1}
                                role="region"
                                aria-label="createnewuser-form failure"
                            >
                                <div>Oops! Something went wrong while submitting the form.</div>
                            </div>
                        </div>
                    </div>
                    <div className="createnewuser-popup-bot">
                        <a href="#" className="btn-outlinegrey mr-10 w-button" onClick={onClose}>
                            Cancel
                        </a>
                        <a href="#" className="btn w-button" onClick={handleSave}>
                            Save
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddNewPopup;