import React, { useRef, useContext } from "react";
import Main from "../Main";
import { PageSignup } from "../../devlink/PageSignup";
import Utilities from "../../common/Utilities";
import { UserContext } from "../../contexts/UserContexts";
import { Loader } from "../common/Loader";


function Signup() {
    const formRef = useRef(null);
    const { user, loading, register } = useContext(UserContext);

    const handleSubmit = async () => {
        const form = formRef.current;
        if (form) {
            let username, email, password, role, displayName, firstName, lastName, country, address, city, state, zip, mobileNumber, avatar;

            username = form.querySelector('input[name="name"]').value;
            email = form.querySelector('input[name="email"]').value;
            password = form.querySelector('input[name="password"]').value;

            const isValidEmail = Utilities.emailValidator(email);

            if (!isValidEmail) {
                alert("Invalid email address");
                return;
            }

            if (!username || !email || !password) {
                alert("Please fill in all fields");
                return;
            }

            try {
                await register({ username, email, password, role, displayName, firstName, lastName, country, address, city, state, zip, mobileNumber, avatar });
            } catch (error) {
                alert(error);
            }
        }
    };

    if (loading) {
        return <Loader />;
    }


    return (
        <Main>
            {!user && <PageSignup formRef={formRef} handleSubmit={handleSubmit} />}
        </Main>
    );
}

export default Signup;
