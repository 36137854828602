import React, { useState, useEffect } from "react";
import Main from "./Main";
import { Sidemenu } from "../devlink/Sidemenu";
import ClientWatcher from "../ClientWatcher";
import WSS from "../services/websocket";
import { ReactiveQuery } from "../QueryProvider";
import moment from "moment";
const wsServer = process.env.REACT_APP_WSS || "ws://localhost:5000";
const wss = new WSS(wsServer);
const client = new ClientWatcher(wss);

function Settings() {
    const [files, setFiles] = useState([]);
    const [isupdate, setisupdate] = useState(null);
    const [currentAudio, setCurrentAudio] = useState(null);
    const [playingId, setPlayingId] = useState(null);

    const { data, isLoading, refetch } = new ReactiveQuery(wss.subscribe.bind(wss), ["uploads"]).find(client.fetchAudioFiles.bind(client));

    useEffect(() => { refetch(); }, [refetch, files, isupdate]);


    const handleFileUpload = (event) => {
        const uploadedFile = event.target.files[0];
        const newFile = {
            id: Date.now(),
            name: uploadedFile.name,
            date: new Date().toLocaleDateString(),
            file: uploadedFile
        };
        setFiles([...files, newFile]);
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('file', uploadedFile);
            fetch(`${process.env.REACT_APP_WSS}/api/upload-audio`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_TOKEN || "test"}` // Replace yourAuthToken with your actual token
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.text();
                })
                .then(() => {
                    refetch();
                })
                .catch(error => {
                    alert('There was a problem with your fetch operation:', error.message);
                });
        });
    };

    const handleDelete = (id) => {
        setisupdate(id);
        client.deleteAudioFile(id);
    };

    const handlePreview = async (id) => {
        try {
            if (playingId === id && currentAudio) {
                currentAudio.pause();
                currentAudio.currentTime = 0;
                setCurrentAudio(null);
                setPlayingId(null);
                return;
            }

            if (currentAudio) {
                currentAudio.pause();
                currentAudio.currentTime = 0;
                setCurrentAudio(null);
            }

            const checkResponse = await fetch(`${process.env.REACT_APP_WSS}/api/audio/${id}`, {
                method: 'HEAD'
            });

            if (!checkResponse.ok) {
                throw new Error('Audio file not accessible');
            }

            const audio = new Audio(`${process.env.REACT_APP_WSS}/api/audio/${id}`);

            audio.addEventListener('ended', () => {
                setCurrentAudio(null);
                setPlayingId(null);
            });

            audio.addEventListener('error', (e) => {
                setCurrentAudio(null);
                setPlayingId(null);
                console.error('Audio playback error:', e);
            });

            await new Promise((resolve, reject) => {
                audio.addEventListener('canplaythrough', resolve);
                audio.addEventListener('error', reject);
            });

            await audio.play();
            setCurrentAudio(audio);
            setPlayingId(id);

        } catch (error) {
            console.error('Preview error:', error);
            setPlayingId(null);
            alert('Failed to preview audio file');
        }
    };

    const handleSelect = (id) => {
        setisupdate(id);
        client.updateSelectedAudio(id);
    };

    useEffect(() => {
        return () => {
            if (currentAudio) {
                currentAudio.pause();
                currentAudio.currentTime = 0;
            }
        };
    }, [currentAudio]);

    const renderPreviewButton = (fileId) => {
        const isPlaying = playingId === fileId;

        return (
            <button
                onClick={() => handlePreview(fileId)}
                style={{
                    ...buttonStyle,
                    backgroundColor: isPlaying ? '#2E7D32' : '#4CAF50',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '6px',
                    minWidth: '100px',
                    position: 'relative',
                    overflow: 'hidden'
                }}
                aria-label={isPlaying ? 'Stop playing audio' : 'Play audio'}
                title={isPlaying ? 'Stop playing audio' : 'Play audio'}
            >
                <span
                    style={{
                        fontSize: '16px',
                        marginRight: '4px'
                    }}
                >
                    {isPlaying ? '⏹' : '▶'}
                </span>
                {isPlaying ? 'Stop' : 'Preview'}

                {isPlaying && (
                    <span
                        style={{
                            position: 'absolute',
                            bottom: '0',
                            left: '0',
                            width: '100%',
                            height: '2px',
                            background: 'rgba(255, 255, 255, 0.3)',
                            animation: 'playingAnimation 2s infinite linear'
                        }}
                    />
                )}
            </button>
        );
    };

    return <Main>
        <div style={{
            backgroundColor: " #fff",
            justifyContent: "flex-start",
            alignItems: "stretch",
            width: "100vw",
            height: "100vh",
            fontFamily: "Inter, sans-serif",
            display: "flex"
        }}>
            <Sidemenu />
            <div style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <div style={{
                    marginBottom: '20rem',
                    padding: '20px',
                    fontFamily: 'Arial, sans-serif'
                }}>
                    <div style={{
                        marginBottom: '20px',
                        textAlign: 'center',
                    }}>
                        <label
                            htmlFor="audio-upload"
                            style={{
                                display: 'inline-block',
                                padding: '12px 24px',
                                backgroundColor: '#6d42f8',
                                color: 'white',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s'
                            }}
                            onMouseOver={(e) => e.target.style.backgroundColor = '#1e1053'}
                            onMouseOut={(e) => e.target.style.backgroundColor = '#6d42f8'}
                        >
                            Upload Audio File
                        </label>
                        <input
                            id="audio-upload"
                            type="file"
                            accept="audio/*"
                            onChange={handleFileUpload}
                            style={{ display: 'none' }}
                        />
                    </div>
                    <div style={containerStyle}>
                        <table style={tableStyle}>
                            <thead style={headerStyle}>
                                <tr>
                                    <th style={headerCellStyle}>Date Uploaded</th>
                                    <th style={headerCellStyle}>Filename</th>
                                    <th style={headerCellStyle}>Actions</th>
                                </tr>
                            </thead>
                        </table>

                        <div style={scrollableBodyStyle}>
                            <table style={tableStyle}>
                                <tbody>
                                    {isLoading ? (
                                        <tr>
                                            <td colSpan="3" style={{ ...tableCellStyle, textAlign: 'center' }}>
                                                Loading...
                                            </td>
                                        </tr>
                                    ) : (
                                        data.map((file, index) => (
                                            <tr
                                                key={index}
                                                style={{
                                                    backgroundColor: file.isSelected ? '#6d42f8' : 'white',
                                                    color: file.isSelected ? 'white' : 'black'
                                                }}
                                            >
                                                <td style={tableCellStyle}>
                                                    {moment(file.createdAt).format("l")}
                                                </td>
                                                <td style={tableCellStyle}>
                                                    {file.originalname}
                                                </td>
                                                <td style={tableCellStyle}>
                                                    <div style={buttonContainerStyle}>
                                                        <button
                                                            onClick={() => handleDelete(file._id)}
                                                            style={deleteButtonStyle}
                                                        >
                                                            Delete
                                                        </button>
                                                        {renderPreviewButton(file._id)}
                                                        <button
                                                            onClick={() => handleSelect(file._id)}
                                                            style={selectButtonStyle(file.isSelected)}
                                                        >
                                                            {file.isSelected ? 'Selected' : 'Select'}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Main >;
}
const buttonStyle = {
    padding: '6px 12px',
    border: 'none',
    borderRadius: '4px',
    color: 'white',
    cursor: 'pointer',
    fontSize: '14px',
    transition: 'opacity 0.3s',
};

const containerStyle = {
    position: 'relative',
    height: '400px'
};

const tableStyle = {
    width: '100%',
    backgroundColor: 'white',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
};

const headerStyle = {
    backgroundColor: '#f5f5f5'
};

const headerCellStyle = {
    position: 'sticky',
    top: 0,
    padding: '16px',
    textAlign: 'left',
    backgroundColor: '#f5f5f5',
    borderBottom: '2px solid #ddd'
};

const scrollableBodyStyle = {
    overflowY: 'auto',
    height: '44rem'
};

const tableCellStyle = {
    padding: '16px',
    borderBottom: '1px solid #ddd'
};

const buttonContainerStyle = {
    display: 'flex',
    gap: '8px',
    justifyContent: 'center'
};

const buttonBaseStyle = {
    padding: '8px 16px',
    borderRadius: '4px',
    border: 'none',
    color: 'white',
    cursor: 'pointer'
};

const deleteButtonStyle = {
    ...buttonBaseStyle,
    backgroundColor: '#FF4D4D'
};

const selectButtonStyle = (isSelected) => ({
    ...buttonBaseStyle,
    backgroundColor: isSelected ? '#9E9E9E' : '#4A90E2'
});

export default Settings;
