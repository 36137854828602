import { parsePhoneNumber } from "awesome-phonenumber";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    emailValidator(email) {
        const re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        return re.test(email);
    },
    isArrayOfObjects(arr) {
        return arr.every((item) => typeof item === "object" && item !== null && !Array.isArray(item));
    },
    numberValidator(input, regionCode = "US") {
        if (!input) return { isValid: false };
        const phone = parsePhoneNumber(input, { regionCode });
        if (phone.valid) {
            let isUS = false;
            switch (phone.regionCode) {
                case "US": case "CA":
                case "AG": case "AI": case "AS": case "BB": case "BM": case "BS":
                case "DM": case "DO": case "GD": case "GU": case "JM": case "KN":
                case "KY": case "LC": case "MP": case "MS": case "PR": case "SX":
                case "TC": case "TT": case "VC": case "VG": case "VI": case "UM":
                    isUS = true;
                    break;
                default:
                    isUS = false;
                    break;
            }
            return {
                isValid: phone.valid,
                type: phone.type,
                fromUS: isUS,
                region: phone.regionCode,
                internationalFormat: phone.number.international,
                nationalFormat: phone.number.national,
                e164Format: phone.number.e164,
                rfc3966Format: phone.number.rfc3966,
                significant: phone.number.significant,
                number: input,
                country: phone.countryCode
            };
        }
        return { isValid: false };
    },
    isObject(item) {
        return (item && typeof item === 'object' && !Array.isArray(item));
    },
    isEmptyObject(obj) {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    deepMerge(target, source) {
        const output = Object.assign({}, target);
        if (this.isObject(target) && this.isObject(source)) {
            Object.keys(source).forEach(key => {
                if (this.isObject(source[key])) {
                    if (!(key in target))
                        Object.assign(output, { [key]: source[key] });
                    else
                        output[key] = this.deepMerge(target[key], source[key]);
                } else {
                    Object.assign(output, { [key]: source[key] });
                }
            });
        }
        return output;
    },
    isValidDate(value) {
        if (value instanceof Date) return true;
        if (typeof value !== "string") return false;
        const date = new Date(value);
        return !isNaN(date.getTime());
    },
    isMillisecondTimestamp(input) {
        const timestamp = Number(input);
        if (isNaN(timestamp)) {
            return false;
        }
        const minTimestamp = 1000000000000; // Roughly March 2001
        const maxTimestamp = 4102444800000; // 2100-01-01

        if (timestamp >= minTimestamp && timestamp <= maxTimestamp) {
            const date = new Date(timestamp);
            return date.getTime() === timestamp;
        }

        return false;
    },
    levenshteinDistance(str1, str2) {
        const m = str1.length;
        const n = str2.length;
        const dp = Array(m + 1).fill(null).map(() => Array(n + 1).fill(0));

        for (let i = 0; i <= m; i++) {
            dp[i][0] = i;
        }
        for (let j = 0; j <= n; j++) {
            dp[0][j] = j;
        }

        for (let i = 1; i <= m; i++) {
            for (let j = 1; j <= n; j++) {
                if (str1[i - 1] === str2[j - 1]) {
                    dp[i][j] = dp[i - 1][j - 1];
                } else {
                    dp[i][j] = Math.min(
                        dp[i - 1][j] + 1,    // deletion
                        dp[i][j - 1] + 1,    // insertion
                        dp[i - 1][j - 1] + 1 // substitution
                    );
                }
            }
        }

        return dp[m][n];
    },
    calculateSimilarity(str1, str2) {
        str1 = str1.toLowerCase();
        str2 = str2.toLowerCase();

        const maxLength = Math.max(str1.length, str2.length);
        const distance = this.levenshteinDistance(str1, str2);
        const similarity = (maxLength - distance) / maxLength;

        return Math.round(similarity * 100 * 100) / 100;
    },
    hasFeature(feature, features) {
        return !!(feature & features);
    }
};
