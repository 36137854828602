class UserWatcher {
    #wss;
    #userId;

    constructor(wss) {
        this.#wss = wss;
    }
    /**
     * @returns {import("./services/websocket").default}
     */
    get Instance() {
        return this.#wss;
    }
    get UserId() {
        return this.#userId;
    }

    async fetchAllUsers({ last }) {
        try {
            return await this.Instance.callFunc("fetchAllUsers", { last });
        } catch (error) {
            throw new Error(error);
        }
    }

    async createUser(data) {
        try {
            return await this.Instance.callFunc("registerUser", data);
        } catch (error) {
            throw new Error(error);
        }
    }

    async updateUser(data) {
        try {
            return await this.Instance.callFunc("updateUser", data);
        } catch (error) {
            throw new Error(error);
        }
    }

    async deleteUser(userId) {
        try {
            return await this.Instance.callFunc("deleteUser", { userId });
        } catch (error) {
            throw new Error(error);
        }
    }

    async blockUser(userId) {
        try {
            return await this.Instance.callFunc("blockUser", { userId });
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default UserWatcher;