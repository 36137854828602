import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from './contexts/UserContexts';
import { USER_ROLES } from './common/const';
import Utilities from "./common/Utilities";

const AdminRoute = ({ children }) => {
    const { user, loading } = useContext(UserContext);

    if (loading) return null;
    const path = window.location.pathname;
    switch (path) {
        case '/settings':
            if (!Utilities.hasFeature(user.role, USER_ROLES.ADMIN | USER_ROLES.SUPER_USER)) return <Navigate to="/" />;
            break;
        case '/users':
            if (!Utilities.hasFeature(user.role, USER_ROLES.SUPER_USER)) return <Navigate to="/" />;
            break;
        default:
            return children;
    }
    return children;
};

export default AdminRoute;
