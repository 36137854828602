import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from './contexts/UserContexts';

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useContext(UserContext);

  if (loading) return null;
  if (!user) return <Navigate to="/login" />;

  return children;
};

export default ProtectedRoute;
