import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { UserProvider, UserContext } from './contexts/UserContexts';
import ProtectedRoute from './ProtectedRoute';
import Login from "./component/auth/Login";
import Signup from "./component/auth/Signup";
import QueryProvider from './QueryProvider';

import "./internal-crm-app.webflow.css";
import Notfound from './component/common/Notfound';
import Settings from './component/Settings';
import Users from './component/Users';
import AdminRoute from './AdminRoute';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <QueryProvider>
      <UserProvider>
        <Routes>
          <Route path='/login' element={
            <UserContext.Consumer>
              {({ user }) => user ? <Navigate to="/" /> : <Login />}
            </UserContext.Consumer>
          } />
          {/* <Route path='/signup' element={
            <UserContext.Consumer>
              {({ user }) => user ? <Navigate to="/" /> : <Signup />}
            </UserContext.Consumer>
          } /> */}
          <Route path='/settings' element={
            <ProtectedRoute>
              <AdminRoute>
                <Settings />
              </AdminRoute>
            </ProtectedRoute>
          } />
          <Route path='/users' element={
            <ProtectedRoute>
              <AdminRoute>
                <Users />
              </AdminRoute>
            </ProtectedRoute>
          } />
          <Route path='/' element={
            <ProtectedRoute>
              <App />
            </ProtectedRoute>
          } />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </UserProvider>
    </QueryProvider>
  </BrowserRouter>
);